import React, {useRef} from "react"
import MathJax from 'react-mathjax2'
import Layout from "../../../../../components/layout"
import SEO from "../../../../../components/seo"

const rel1 = "\vtext {drift(%)} = 0.75 \pm 0.16";
const rel2 = "D = 400mm";
const rel3 = "L = 1400mm";
const rel4 = "(P/(f_{ce}' A_{g})) = 0.1";
const rel5 = "\vrho _{l}(%) = 1.61";
const rel6 = "\vrho _{s}(%) = 0.46";
const rel7 = "f_{c}' = 20 MPa";
const rel8 = "f_{y} = 325 MPa";
const rel9 = "f_{yh} = 325 MPa";
const rel10 = "M/VH = 3.5";
const rel11 = "\vtext {Steel jacket (288 MPa), 1.0mm thick}";

const rel12 = "\vtext {drift(%)} = 5.04 \pm 1.79";
const rel13 = "\vtext {drift(%)} = 3.63 \pm 0.53";
const rel14 = "\vtext {drift(%)} = 6.13 \pm 0.51";
const rel15 = "D = 610mm";
const rel16 = "L = 2438, 3429mm";
const rel17 = "(P/(f_{ce}' A_{g})) = 0.1";
const rel18 = "\vrho _{l}(%) = 1.94";
const rel19 = "\vrho _{s}(%) = 1.03";
const rel20 = "f_{c}' = 37 MPa";
const rel21 = "f_{y} = 299, 481 MPa";
const rel22 = "f_{yh} = 299, 481 MPa";
const rel23 = "M/VH = 2.0, 2.8";
const rel24 = "\vtext {GFRP jacket 8.4-22.8 mm thick, } f_{uf} = 424-937 MPa";
const rel25 = "\vtext {CFRP jacket 0.66-8.38 mm thick, } f_{uf} = 1245-4443 MPa";

const rel26 = "\vtext {drift(%)} = 6.0";
const rel27 = "D = 760mm";
const rel28 = "L = 3250mm";
const rel29 = "(P/(f_{ce}' A_{g})) = 0.12";
const rel30 = "\vrho _{l}(%) = 1.15, 1.32";
const rel31 = "\vrho _{s}(%) = 0.31, 0.29";
const rel32 = "f_{c}' = 25.5 MPa";
const rel33 = "f_{y} = 343 MPa";
const rel34 = "f_{yh} = 490 MPa";
const rel35 = "M/VH = 4.3";
const rel36 = "\vtext {Steel jacket (257 MPa), 3.0 mm thick}";

const rel37 = "\vtext {drift(%)} = 0.7, 0.65";
const rel38 = "D = 610mm";
const rel39 = "L = 2438mm";
const rel40 = "(P/(f_{ce}' A_{g})) = 0.10";
const rel41 = "\vrho _{l}(%) = 1.94";
const rel42 = "\vrho _{s} = 0.31, 0.29";
const rel43 = "f_{c}' = 31 MPa";
const rel44 = "f_{y} = 276 MPa";
const rel45 = "f_{yh} = 303 MPa";
const rel46 = "M/VH = 4.0";
const rel47 = "\vtext {GFRP sheets 2.5 mm thick, } \vepsilon_{rup} = 0.018, f_{uf} = \vtext {690 MPa, 5 layers}";

const rel48 = "\vtext {drift(%)} = 5.09";

const rel49 = "\vtext {drift(%)} = 1.97, P/P_{0} = 0.54, \vrho_{s} = 0.3%, \vtext {1 layer of 1.25mm thick GFRP}";
const rel50 = "D = 356mm";
const rel51 = "L = 1473mm";
const rel52 = "(P/(f_{ce}' A_{g})) = 0.27, 0.54";
const rel53 = "\vrho _{l} = 0.03";
const rel54 = "\vrho _{s} = 0.3, 1.12";
const rel55 = "f_{c}' = 40 MPa";
const rel56 = "f_{y} = 400 MPa";
const rel57 = "f_{yh} = 420 MPa";

const rel58 = "\vtext {drift(%)} = 3.3, P/P_{0} = 0.54, \vrho_{s} = 0.3%, \vtext {1 layer of 1.0mm thick CFRP}";
const rel59 = "\vtext {drift(%)} = 7.8, P/P_{0} = 0.27, \vrho_{s} = 0.3%, \vtext {1 layer of 0.5mm thick CFRP}";
const rel60 = "\vtext {drift(%)} = 7.4, P/P_{0} = 0.27, \vrho_{s} = 0.3%, \vtext {1 layer of 1.25mm thick GFRP}";
const rel61 = "\vtext {drift(%)} = 5.1, P/P_{0} = 0.27, \vrho_{s} = 0.3%, \vtext {1 layer of 1.0mm thick CFRP bands}";
const rel62 = "\vtext {drift(%)} = 2.22, P/P_{0} = 0.54, \vrho_{s} = 0.3%, \vtext {1 layer of 1.25mm thick GFRP}";
const rel63 = "\vtext {drift(%)} = 3.07, P/P_{0} = 0.54, \vrho_{s} = 0.3%, \vtext {1 layer of 1.25mm thick GFRP}";
const rel64 = "\vtext {drift(%)} = 3.81, P/P_{0} = 0.54, \vrho_{s} = 0.3%, \vtext {1 layer of 1.0mm thick CFRP}";
const rel65 = "\vtext {drift(%)} = 8.9, P/P_{0} = 0.27, \vrho_{s} = 0.3%, \vtext {1 layer of 0.5mm thick CFRP}";
const rel66 = "\vtext {drift(%)} = 7.8, P/P_{0} = 0.27, \vrho_{s} = 0.3%, \vtext {1 layer of 1.25mm thick GFRP}";
const rel67 = "\vtext {drift(%)} = 5.4, P/P_{0} = 0.27, \vrho_{s} = 0.3%, \vtext {1 layer of 1.0mm thick CFRP bands}";



const rel101 = "\vmu_{d} = 1.0";
const rel112 = "\vmu_{d} = 6.63 \pm 1.63";
const rel113 = "\vmu_{d} = 11.75, \vtext {Grade 40 steel - } \vmu_{d} = 4.4, \vtext {Grade 60 steel}";
const rel114 = "\vmu_{d} = 6.9 \pm 0.57";
const rel126 = "\vmu_{d} = 10.7";
const rel137 = "\vmu_{d} = 1.0";
const rel148 = "\vmu_{d} = 7.3";






const LSExperimental = () => {
  //javascript
  const title = 'Retrofitted Piers';
  const metatitle = 'Retrofitted Piers';
  const description = 'Meta Description for Retrofitted Piers';
  const metadescription = description;

  const scrollToRef = (ref) => window.scrollTo({
		left:0,
		top:ref.current.offsetTop,
		behavior: 'smooth'
	});
  const Ref1 = useRef(null);
  const Ref2 = useRef(null);
  const Ref3 = useRef(null);
  const Ref4 = useRef(null);
  const Ref5 = useRef(null);
  const Ref6 = useRef(null);
  const Ref7 = useRef(null);
  const Ref8 = useRef(null);
  const Ref9 = useRef(null);
  const Ref10 = useRef(null);
  const Ref11 = useRef(null);
  const Ref12 = useRef(null);
  const Ref13 = useRef(null);
  const Ref14 = useRef(null);
  const Ref15 = useRef(null);

	const executeScroll = (e, ref) => {
		e.preventDefault();
		scrollToRef(ref);
  }

  
  return(
    <Layout bodyclass="ls-experimental" title={title}>
      <SEO
        title={metatitle}
        description={metadescription}
      />
      <nav className="anchornav">
        <ul>
          <li>
            <a className="more actclass" href="../cylindrical/">Cylindrical Piers</a>
          </li>
          <li>
            <a className="more" href="../rectangular/">Rectangular Piers</a>
          </li>
          <li>
            <a className="more" href="../hollow-rectangular/">Hollow Rectangular Piers</a>
          </li>
          <li>
            <a className="more" href="../hollow-circular/">Hollow Circular Piers</a>
          </li>
        </ul>
      </nav>
      <div className="content">
        <section className="exp-section">
          <h1>Cylindrical Piers</h1>
          <div className="content-table">
            <h2>Table 1: Cylindrical Piers: Limit state thresholds in terms of drift</h2>
            <table>
              <thead>
                <tr>
                  <th colSpan={6}>Engineering Demand Parameter: Drift (%)</th>
                </tr>
                <tr>
                  <th>References</th>
                  <th>Limit States</th>
                  <th>Threshold Values</th>
                  <th>Description</th>
                  <th>Loading Type</th>
                  <th>Specimen Characteristics</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan={2}><button onClick={ e => executeScroll(e, Ref1)}>[1]</button> Choi, E., Chung, Y.S, Park, C., Kim, D.J. (2013)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel1}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Yielding point of system (bilin. curve)</td>
                  <td rowSpan={2}>Cyclic loading</td>
                  <td rowSpan={2}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel2}</MathJax.Node><br /><MathJax.Node inline>{rel3}</MathJax.Node><br /><MathJax.Node inline>{rel4}</MathJax.Node><br /><MathJax.Node inline>{rel5}</MathJax.Node><br /><MathJax.Node inline>{rel6}</MathJax.Node><br /><MathJax.Node inline>{rel7}</MathJax.Node><br /><MathJax.Node inline>{rel8}</MathJax.Node><br /><MathJax.Node inline>{rel9}</MathJax.Node><br /><MathJax.Node inline>{rel10}</MathJax.Node><br /><MathJax.Node inline>{rel11}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel12}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>15% drop of maximum lateral resistance, longitudinal bar buckling</td>
                </tr>
                <tr className="even">
                  <td><button onClick={ e => executeScroll(e, Ref2)}>[2]</button> Haroun, M. A., Mosallam, A. S., Feng, M. Q., Elsanadedy, H. M. (2003)</td>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div>Shear enhancement columns<br /><MathJax.Node inline>{rel13}</MathJax.Node><br /><br />Lap splice columns<br /><MathJax.Node inline>{rel14}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>20% drop of maximum lateral resistance, concrete crushing within the plastic hinge region, composite jacket failure, long. bar buckling</td>
                  <td>Cyclic loading</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel15}</MathJax.Node><br /><MathJax.Node inline>{rel16}</MathJax.Node><br /><MathJax.Node inline>{rel17}</MathJax.Node><br /><MathJax.Node inline>{rel18}</MathJax.Node><br /><MathJax.Node inline>{rel19}</MathJax.Node><br /><MathJax.Node inline>{rel20}</MathJax.Node><br /><MathJax.Node inline>{rel21}</MathJax.Node><br /><MathJax.Node inline>{rel22}</MathJax.Node><br /><MathJax.Node inline>{rel23}</MathJax.Node><br /><MathJax.Node inline>{rel24}</MathJax.Node><br /><MathJax.Node inline>{rel25}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td><button onClick={ e => executeScroll(e, Ref3)}>[3]</button> Li, Y.F., Hwang, J.S., Chen, S.H., Hsieh, Y.M. (2005)</td>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel26}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Concrete crushing, long. bars fracture, 40% drop in lateral resistance</td>
                  <td>Cyclic loading</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel27}</MathJax.Node><br /><MathJax.Node inline>{rel28}</MathJax.Node><br /><MathJax.Node inline>{rel29}</MathJax.Node><br /><MathJax.Node inline>{rel30}</MathJax.Node><br /><MathJax.Node inline>{rel31}</MathJax.Node><br /><MathJax.Node inline>{rel32}</MathJax.Node><br /><MathJax.Node inline>{rel33}</MathJax.Node><br /><MathJax.Node inline>{rel34}</MathJax.Node><br /><MathJax.Node inline>{rel35}</MathJax.Node><br /><MathJax.Node inline>{rel36}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr className="even">
                  <td rowSpan={2}><button onClick={ e => executeScroll(e, Ref4)}>[4]</button> Ma, R. and Xiao, Y. (1999)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel37}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Yielding of longitudinal reinforcement</td>
                  <td rowSpan={2}>Cyclic loading</td>
                  <td rowSpan={2}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel38}</MathJax.Node><br /><MathJax.Node inline>{rel39}</MathJax.Node><br /><MathJax.Node inline>{rel40}</MathJax.Node><br /><MathJax.Node inline>{rel41}</MathJax.Node><br /><MathJax.Node inline>{rel42}</MathJax.Node><br /><MathJax.Node inline>{rel43}</MathJax.Node><br /><MathJax.Node inline>{rel44}</MathJax.Node><br /><MathJax.Node inline>{rel45}</MathJax.Node><br /><MathJax.Node inline>{rel46}</MathJax.Node><br /><MathJax.Node inline>{rel47}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr className="even">
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel48}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>De-bonding of lap-spliced longitudinal bars, 35% drop in lateral load capacity</td>
                </tr>
                <tr>
                  <td rowSpan={12}><button onClick={ e => executeScroll(e, Ref5)}>[5]</button> Sheikh, S. A. and Yau, G. (2002)</td>
                  <td rowSpan={6}>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel49}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td rowSpan={6}>Yield of spiral/ buckling of long. bars</td>
                  <td rowSpan={12}>Cyclic loading</td>
                  <td rowSpan={12}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel50}</MathJax.Node><br /><MathJax.Node inline>{rel51}</MathJax.Node><br /><MathJax.Node inline>{rel52}</MathJax.Node><br /><MathJax.Node inline>{rel53}</MathJax.Node><br /><MathJax.Node inline>{rel54}</MathJax.Node><br /><MathJax.Node inline>{rel55}</MathJax.Node><br /><MathJax.Node inline>{rel56}</MathJax.Node><br /><MathJax.Node inline>{rel57}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td>-</td>
                </tr>
                <tr>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel58}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel59}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel60}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel61}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td rowSpan={6}>LS4</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel62}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td rowSpan={6}>Rupture of FRP</td>
                </tr>
                <tr>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel63}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel64}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel65}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel66}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel67}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                
              </tbody>
            </table>
          </div>
          <div className="references">
            <h4>References</h4>
            <ol>
              <li ref={Ref1}>Choi, E., Chung, Y.S, Park, C., Kim, D.J. (2013). Seismic performance of circular RC columns retrofitted with prefabricated steel wrapping jackets, Magazine of Concrete Research, Vol. 65, No. 23, pp 1429-1440, <a href="http://dx.doi.org/10.1680/macr.13.00177">http://dx.doi.org/10.1680/macr.13.00177.</a></li>
              <li ref={Ref2}>Haroun, M. A., Mosallam, A. S., Feng, M. Q., Elsanadedy, H. M. (2003). Experimental Investigation of Seismic Repair and Retrofit of Bridge Columns by Composite Jackets, Journal of REINFORCED PLASTICS AND COMPOSITES, Vol. 22, No. 14, pp 1243-1268, <a href="https://doi.org/10.1177%2F0731684403035573">https://doi.org/10.1177%2F0731684403035573</a></li>
              <li ref={Ref3}>Li, Y.F., Hwang, J.S., Chen, S.H., Hsieh, Y.M. (2005). A STUDY OF REINFORCED CONCRETE BRIDGE COLUMNS RETROFITTED BY STEEL JACKETS, Journal of Chinese Institute of Engineers, Vol. 28, No.2, pp 319-328, <a href="http://dx.doi.org/10.1080/02533839.2005.9670997">http://dx.doi.org/10.1080/02533839.2005.9670997.</a></li>
              <li ref={Ref4}>Ma, R. and Xiao, Y. (1999). Seismic Retrofit and Repair of Circular Bridge Columns with Advanced Composite Materials, Earthquake Spectra, Vol. 15, No. 4, pp 747-764.</li>
              <li ref={Ref5}>Sheikh, S. A. and Yau, G. (2002). Seismic Behavior of Concrete Columns Confined with Steel and Fiber-Reinforced Polymer, ACI Structural Journal, Vol. 99, No. 1, pp 72 – 80, <a href="https://doi.org/10.14359/11037">https://doi.org/10.14359/11037.</a></li>
            </ol>
          </div>
        </section>
        <section className="exp-section">
          <div className="content-table">
            <h2>Table 2: Cylindrical Piers: Limit state thresholds in terms of displacement ductility</h2>
            <table>
              <thead>
                <tr>
                  <th colSpan={6}>Engineering Demand Parameter: Displacement ductility</th>
                </tr>
                <tr>
                  <th>References</th>
                  <th>Limit States</th>
                  <th>Threshold Values</th>
                  <th>Description</th>
                  <th>Loading Type</th>
                  <th>Specimen Characteristics</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan={2}><button onClick={ e => executeScroll(e, Ref11)}>[1]</button> Choi, E., Chung, Y.S, Park, C., Kim, D.J. (2013)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel101}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Yielding point of system (bilin. curve)</td>
                  <td rowSpan={2}>Cyclic loading</td>
                  <td rowSpan={2}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel2}</MathJax.Node><br /><MathJax.Node inline>{rel3}</MathJax.Node><br /><MathJax.Node inline>{rel4}</MathJax.Node><br /><MathJax.Node inline>{rel5}</MathJax.Node><br /><MathJax.Node inline>{rel6}</MathJax.Node><br /><MathJax.Node inline>{rel7}</MathJax.Node><br /><MathJax.Node inline>{rel8}</MathJax.Node><br /><MathJax.Node inline>{rel9}</MathJax.Node><br /><MathJax.Node inline>{rel10}</MathJax.Node><br /><MathJax.Node inline>{rel11}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel112}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>15% drop of maximum lateral resistance, longitudinal bar buckling</td>
                </tr>
                <tr className="even">
                  <td><button onClick={ e => executeScroll(e, Ref12)}>[2]</button> Haroun, M. A., Mosallam, A. S., Feng, M. Q., Elsanadedy, H. M. (2003)</td>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div>Shear enhancement columns<br /><MathJax.Node inline>{rel113}</MathJax.Node><br /><br />Lap splice columns<br /><MathJax.Node inline>{rel114}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>20% drop of maximum lateral resistance, concrete crushing within the plastic hinge region, composite jacket failure, long. bar buckling</td>
                  <td>Cyclic loading</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel15}</MathJax.Node><br /><MathJax.Node inline>{rel16}</MathJax.Node><br /><MathJax.Node inline>{rel17}</MathJax.Node><br /><MathJax.Node inline>{rel18}</MathJax.Node><br /><MathJax.Node inline>{rel19}</MathJax.Node><br /><MathJax.Node inline>{rel20}</MathJax.Node><br /><MathJax.Node inline>{rel21}</MathJax.Node><br /><MathJax.Node inline>{rel22}</MathJax.Node><br /><MathJax.Node inline>{rel23}</MathJax.Node><br /><MathJax.Node inline>{rel24}</MathJax.Node><br /><MathJax.Node inline>{rel25}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td><button onClick={ e => executeScroll(e, Ref13)}>[3]</button> Li, Y.F., Hwang, J.S., Chen, S.H., Hsieh, Y.M. (2005)</td>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel126}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Concrete crushing, long. bars fracture, 40% drop in lateral resistance</td>
                  <td>Cyclic loading</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel27}</MathJax.Node><br /><MathJax.Node inline>{rel28}</MathJax.Node><br /><MathJax.Node inline>{rel29}</MathJax.Node><br /><MathJax.Node inline>{rel30}</MathJax.Node><br /><MathJax.Node inline>{rel31}</MathJax.Node><br /><MathJax.Node inline>{rel32}</MathJax.Node><br /><MathJax.Node inline>{rel33}</MathJax.Node><br /><MathJax.Node inline>{rel34}</MathJax.Node><br /><MathJax.Node inline>{rel35}</MathJax.Node><br /><MathJax.Node inline>{rel36}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr className="even">
                  <td rowSpan={2}><button onClick={ e => executeScroll(e, Ref14)}>[4]</button> Ma, R. and Xiao, Y. (1999)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel137}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Yielding of longitudinal reinforcement</td>
                  <td rowSpan={2}>Cyclic loading</td>
                  <td rowSpan={2}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel38}</MathJax.Node><br /><MathJax.Node inline>{rel39}</MathJax.Node><br /><MathJax.Node inline>{rel40}</MathJax.Node><br /><MathJax.Node inline>{rel41}</MathJax.Node><br /><MathJax.Node inline>{rel42}</MathJax.Node><br /><MathJax.Node inline>{rel43}</MathJax.Node><br /><MathJax.Node inline>{rel44}</MathJax.Node><br /><MathJax.Node inline>{rel45}</MathJax.Node><br /><MathJax.Node inline>{rel46}</MathJax.Node><br /><MathJax.Node inline>{rel47}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr className="even">
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel148}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>De-bonding of lap-spliced longitudinal bars, 35% drop in lateral load capacity</td>
                </tr>
                
                
              </tbody>
            </table>
          </div>
          <div className="references">
            <h4>References</h4>
            <ol>
              <li ref={Ref11}>Choi, E., Chung, Y.S, Park, C., Kim, D.J. (2013). Seismic performance of circular RC columns retrofitted with prefabricated steel wrapping jackets, Magazine of Concrete Research, Vol. 65, No. 23, pp 1429-1440, <a href="http://dx.doi.org/10.1680/macr.13.00177">http://dx.doi.org/10.1680/macr.13.00177.</a></li>
              <li ref={Ref12}>Haroun, M. A., Mosallam, A. S., Feng, M. Q., Elsanadedy, H. M. (2003). Experimental Investigation of Seismic Repair and Retrofit of Bridge Columns by Composite Jackets, Journal of REINFORCED PLASTICS AND COMPOSITES, Vol. 22, No. 14, pp 1243-1268, <a href="https://doi.org/10.1177%2F0731684403035573">https://doi.org/10.1177%2F0731684403035573</a></li>
              <li ref={Ref13}>Li, Y.F., Hwang, J.S., Chen, S.H., Hsieh, Y.M. (2005). A STUDY OF REINFORCED CONCRETE BRIDGE COLUMNS RETROFITTED BY STEEL JACKETS, Journal of Chinese Institute of Engineers, Vol. 28, No.2, pp 319-328, <a href="http://dx.doi.org/10.1080/02533839.2005.9670997">http://dx.doi.org/10.1080/02533839.2005.9670997.</a></li>
              <li ref={Ref14}>Ma, R. and Xiao, Y. (1999). Seismic Retrofit and Repair of Circular Bridge Columns with Advanced Composite Materials, Earthquake Spectra, Vol. 15, No. 4, pp 747-764.</li>
            </ol>
          </div>
        </section>
      </div>
      
    </Layout>
  )
}

export default LSExperimental
